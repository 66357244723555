import { useStaticQuery, graphql } from 'gatsby';

export const useTagsQuery = () => {
  const q = useStaticQuery(
    graphql`
      query AllTagsQuery {
        code: allMarkdownRemark(
          filter: { fields: { sourceInstanceName: { eq: "code" } } }
        ) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
          edges {
            node {
              id
            }
          }
        }
        general: allMarkdownRemark(
          filter: { fields: { sourceInstanceName: { eq: "general" } } }
        ) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
          edges {
            node {
              id
            }
          }
        }
      }
    `
  );
  return q;
};
