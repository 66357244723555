module.exports = {
  // Basics
  codePostDir: 'code',
  generalPostDir: 'general',
  // Meta Data
  siteTitle: 'Justin Formentin',
  siteTitleAlt: 'JustinFormentin',
  siteShortName: 'JustinGatsby',
  siteBanner: '/social/banner.jpg',
  siteLogo: '/favicon.png',
  siteUrl: 'https://justinformentin.com',
  pathPrefix: '/', // Prefix for all links. Site deployed to example.com/whatever your pathPrefix should be "whatever"
  siteDescription:
    'Justin Formentin - Writing about code.',
  author: 'Justin Formentin', // Author for schema.org JSONLD
  ogSiteName: 'justinformentin', // Facebook Site Name
  ogLanguage: 'en_US', // og:language
  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // IDs
  googleAnalyticsID: 'UA-107933755-3',
  twitter: '@whatjustin',
  // Manifest
  themeColor: '#3498DB',
  backgroundColor: '#2e3246',
};

// module.exports = {
//   pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
//   siteTitle: 'Justin Formentin', // Navigation and Site Title
//   siteTitleAlt: 'Justin Formentin', // Alternative Site title for SEO
//   siteTitleShort: 'JustinFormentin', // short_name for manifest
//   siteHeadline: 'Justin Formentin', // Headline for schema.org JSONLD
//   siteUrl: 'https://justinformentin.com', // Domain of your site. No trailing slash!
//   siteLanguage: 'en', // Language Tag on <html> element
// }